<template>
  <div>
    <p>
      It was an epic showdown in the LEGO Universe Creation Lab: Pirates vs. Ninjas in a hotly contested race to unlock new creative challenges!
    </p>
    <p>
      Now it’s time to set aside all competition and celebrate both teams’ creativity. We’ve enlisted a couple LEGO Universe artists to review a few of the submissions that made for such a great race. No matter whether you like pirates or ninjas best, everyone can respect the artistry in these awesome creations!
    </p>
    <p>
      LEGO Universe art director, Phillip Atencio, dove into the “Pirates: Create a watercraft” challenge and came up with comments on these three submissions:
    </p>
    <!-- pic84CAAA5A-2091-46B9-B768-C55055108294.jpg -->
    <img class="rounded x-auto" src="@/assets/news-network/pirates-ninjas-1.jpg">
    <p>
      <router-link :to="{ name: 'creation', params: { id: 'aec45a93-3d39-4ba5-aeb5-c8096514a2ea', challengeId: '4F16E2B6-4084-48D0-A8D5-353A59400548' } }"><strong>Piratenschiff: "Black Bird"</strong></router-link><strong>, by Kreatur</strong>
    </p>
    <p>
      <em>Superb creative use of special FX materials to create the sails! This inspired improvisation echoes the solutions LEGO Universe animators come up with when simulating unseen physical forces. Billowing sails are an excellent way to visualize something invisible like wind. Creativity like this is highly coveted on the LEGO Universe production team.</em>
    </p>
    <!-- picD9BA59893DAC66E7C62D3F8FFDA02AF4.jpg -->
    <img class="rounded x-auto" src="@/assets/news-network/pirates-ninjas-2.jpg">
    <p>
      <router-link :to="{ name: 'creation', params: { id: '7bbd8c08-5968-4eb3-af4b-0ad07e038629', challengeId: 'D66A63DC-2E72-462D-92BB-07228EE5BBE7' } }"><strong>black seas barracuda (box 6285)</strong></router-link><strong>, by Ric 166</strong>
    </p>
    <p>
      <em>This is a stunning model complete with simulated rigging and billowing sails. This creation is reminiscent of a vessel used in LEGO Universe. The skybox representation is also a nice touch.<br></em>
    </p>
    <!-- picB6ADAE8279E1D3B3C917590AD31308B8.jpg -->
    <img class="rounded x-auto" src="@/assets/news-network/pirates-ninjas-3.jpg">
    <p>
      <router-link :to="{ name: 'creation', params: { id: '420f4a06-88d0-48c1-be9f-8bbe3142d684', challengeId: 'D66A63DC-2E72-462D-92BB-07228EE5BBE7' } }"><strong>jolly sink</strong></router-link><strong>, by croc36</strong>
    </p>
    <p>
      <em>The colors used to create the pirate costumes in this composition allow them to stand out from the surroundings. LEGO Universe artists make similar considerations when creating visual designs for important non-player characters. The small boat is well equipped for the voyage ahead and visually immersed in a riverside environment. Because of these details, this composition could be used to inspire LEGO Universe world artists. Fantastic lighting and reflections!</em>
    </p>
    <p>
      Lead LEGO Universe VFX artist, Mike Talarico, peeked into the “Ninjas: Make a hideout” challenge and spied these creative ninja hiding places:
    </p>
    <!-- picB13BA1538D2E4D117C4CF133B9B210DD.jpg -->
    <img class="rounded x-auto" src="@/assets/news-network/pirates-ninjas-4.jpg">
    <p>
      <router-link :to="{ name: 'creation', params: { id: 'ba2e5c5c-1f27-49f6-b2f2-8710eed88cb3', challengeId: 'F7EC4205-E798-4CC6-BFB7-0F81F51674D6' } }"><strong>Unterschlupf im Eis</strong></router-link><strong>, by Comicboy888</strong>
    </p>
    <p>
      <em>I chose this submission because it’s so well thought out! I really like how you created a complete environment for the ninjas, including a lookout turret, a relaxation area and ninja-like hiding areas. As a LEGO Universe effects artist, I can imagine wonderful effects for the pirate-freezing area too. Keep up the great work!</em>
    </p>
    <!-- pic24BABD846DE906C7BD8D0755413AE2CE.jpg -->
    <img class="rounded x-auto" src="@/assets/news-network/pirates-ninjas-5.jpg">
    <p>
      <router-link :to="{ name: 'creation', params: { id: 'd213edf7-e648-4377-ac75-adbccba23695', challengeId: '7CE0D560-DC13-4CC8-932A-43866450ED87' } }"><strong>Ninja Cave Bunker</strong></router-link><strong>, by Beeky31</strong>
    </p>
    <p>
      <em>Wow, nice job! This is very similar to some of the ninja hideouts we’re creating in LEGO Universe. It has a very natural look, and the description shows you’re thinking of the whole story, like why it’s there and how it’s used. I can visualize ninjas materializing into the scene in a big puff of smoke and then disappearing inside the cave where the pirates could never find them. Great stuff, keep creating!</em>
    </p>
    <!-- pic51E583CE4CDB5EA6F6408BF7EEF6EBB2.jpg -->
    <img class="rounded x-auto" src="@/assets/news-network/pirates-ninjas-6.jpg">
    <p>
      <router-link :to="{ name: 'creation', params: { id: 'cd6b96a6-a465-4766-8be7-0df5d3df56de', challengeId: '7CE0D560-DC13-4CC8-932A-43866450ED87' } }"><strong>My Ninja Hideout</strong></router-link><strong>, by Danjam.T</strong>
    </p>
    <p>
      <em>Super good! You know your ninjas, Danjam.T! Amazing pagoda, and the terrain and water are also superbly thought through. The ninjas battling it out in mid-air are a great touch. If I were creating visual effects for this scene, I see moving water, misty mountains and loads of attack effects for the big battle. You have a real talent!</em>
    </p>
    <p>
      “Great job” to everyone who’s uploading their inspiration in the Creation Lab! Keep those creations coming. Someday soon one of yours might be featured right here on the LEGO Universe News Network!<br>
    </p>
  </div>
</template>
